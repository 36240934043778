import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useGetUserQuery, useGetUsersQuery } from "../../../store/reducers/adminApi";
import Image from "react-bootstrap/Image";
import Badge from "react-bootstrap/Badge";
import { useNavigate } from "react-router";

export function Users() {
  const [page, setPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState<string|null>(null)
  // const [email, setEmail] = useState<string|null>(null)
  // const [name, setName] = useState<string|null>(null)

  const { isSuccess, isError, isFetching, error, data: users, refetch } = useGetUsersQuery({
      page: page,
      searchQuery: searchQuery,
      name: null,
      email: null
  })

  const navigate = useNavigate();

  const updateSearchQuery = () => {
        const searchInput = document.getElementById("search-input") as HTMLInputElement;
        const searchQuery = searchInput.value;
        if (searchQuery === "" || searchQuery === null) {
            setSearchQuery(null);
            setPage(1)
            return;
        }
        if (searchQuery.length < 3) {
            alert("Search query must be at least 5 characters long. Otherwise - server uses too much memory while going all over the users in the DB and might crash.");
            return;
        }
        setSearchQuery(searchQuery);
        setPage(1);
        refetch();
  }

  // const onEmailChange = (newEmail) => {
  //     setEmail(newEmail);
  // }
  // const onNameChange = (newName) => {
  //     setName(newName);
  // }

  return (
      <div>
        Page # {page}
          {isFetching ? "Loading..." : null}
          <div>
              <label htmlFor="search">Search:
              </label>
              <div>
              <input type="search" id={"search-input"} placeholder={"Search by email or name"} />
                  <a href={"#"} style={{marginLeft: 5}} onClick={() => { updateSearchQuery() }}>Search</a>
              </div>
          </div>
        <Table striped bordered hover className="table-hover">
          <thead>
          <tr>
            <th>
                <div>Email</div>
                {/*<div>*/}
                {/*    <input type="search" placeholder={"Search by email"}*/}
                {/*    value={email}*/}
                {/*    onChange={e => onEmailChange(e.target.value)}/>*/}
                {/*    {email}*/}
                {/*</div>*/}
            </th>
            <th>
                <div>Name</div>
                {/*<div>*/}
                {/*    <input type="search" placeholder={"Search by name"}*/}
                {/*    value={name}*/}
                {/*    onChange={e => onNameChange(e.target.value)}/>*/}
                {/*</div>*/}
            </th>

            <th>Created At</th>
            <th>Total Spend, $</th>
            <th>Chats #</th>
            <th>Chat Requests #</th>
          </tr>
          </thead>
          <tbody>
          {users &&
              users.map((user) => {
                if (user.user_role === "user")
                  console.log(user);
                let totalSpend = 0;
                if (user.payments) {
                  user.payments.forEach((payment) => {
                    totalSpend += payment.charge_amount;
                  });
                }
                const uniqueChats = new Set((user.chatParticipants ?? []).map((e) => e.chat_id))

                return (
                    <tr key={user.userID} onClick={() => navigate(`/user/${user.userID}`)}>
                      <td>{user.email}</td>
                      <td>{user.name}</td>
                      <td>{moment(user.created_at).format("MM-DD-YYYY")}</td>
                      <td style={{color: totalSpend == 0 ? "#c4c4c4" : ""}}>${(totalSpend/100).toFixed(2)}</td>
                      <td style={{color: uniqueChats.size == 0 ? "#c4c4c4" : ""}}>{uniqueChats.size}</td>
                      <td style={{color: user.chatRequestsSent == null || user.chatRequestsSent.length == 0 ? "#c4c4c4" : ""}}>{(user.chatRequestsSent ?? []).length}</td>
                    </tr>
                );
              })}
          </tbody>
        </Table>
        Current Page: {page}
        {page > 1 ? <button style={{marginRight: '3px', marginLeft: '3px'}} onClick={() => setPage(page - 1)}>
          Previous
        </button> : <></>}

        {users != null && users.length > 0 ? <button style={{marginRight: '3px', marginLeft: '3px'}} onClick={() => setPage(page + 1)}>
          Next
        </button> : <></>}
      </div>
  );
}
